import React, { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import AsyncSelect from 'react-select/async';
import debounce from "lodash/debounce";

// Material-UI Components
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from '../../assets/logo/lilly.png';
import ExampleImg1 from '../../assets/background/leaf_bg.jpg';
import { getHospitalList } from "../../SDK/user";

// General Styles
const useStyles = makeStyles((theme) => ({
	logo: {
		margin: '60px auto 20px',
		width: 200,
	},
	paper: {
		marginTop: "50px",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		'& .MuiButton-fullWidth': {
            backgroundColor: '#2F80ED',
            color: '#fff'
        },
		'& .MuiButton-contained.Mui-disabled' : {
			color: 'rgba(0, 0, 0, 0.26)',
			backgroundColor: 'rgba(0, 0, 0, 0.12)',
		}
		
	},
	image: {
		backgroundSize: "cover",
		backgroundColor: "#fafafa",
		backgroundImage: `url(${ExampleImg1})`,
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		height: "100vh",
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		// marginTop: theme.spacing(1),
	},
	text: {
		color: '#333',
		margin: '50px auto 20px',
		fontSize: 16,
	},
	submit: {
		margin: theme.spacing(2, 0, 2),
	},
}));

const Consent = () => {
	const [selectedValue, setSelectedValue] = useState(null);

	const classes = useStyles();
	const history = useHistory();

	useEffect(() => {
        if(!localStorage.user) {
            history.push('/login')
        }
    }, [])
	
	const loadOptions = useCallback((inputValue, callback) => {
		getHospitalList()
		.then(({response}) => {
			const options = response.entry.map(e => {
				return {
					label: e.resource.name,
					address: e.resource.address
				}
			})
			callback && callback(options.filter(e => e.label.toLowerCase().includes(inputValue.toLowerCase())))
		}).catch(err => {
			console.log(err)
		})
	}, [])

	const debounceLoadOptions = useMemo(() => {
		return debounce(loadOptions, 1000)
	}, [loadOptions])

	return (
		<Grid container>
			<Grid className={classes.image} item sm={4} md={6} />
			<Grid item xs={12} sm={8} md={6}>
				<Container component="main" maxWidth="xs">
					<div className={classes.paper}>
						<div className={classes.logo}>
                            <img src={logo} alt="test" style={{width: '90%'}}/>
                        </div>                                                                  
						<form className={classes.form} noValidate>
							<p className={classes.text}>
								{'To provide your tailored health recommendations, please select the hospital you go to and allow us to access your health information.'}
							</p>
							<AsyncSelect 
								cacheOptions
								defaultOptions
								value={selectedValue}
								placeholder={'Select your health facility'}
								getOptionLabel={(e) => e.label}
								getOptionValue={(e) => e.address}
								loadOptions={debounceLoadOptions}
								onChange={value => setSelectedValue(value)}
							/>
							{
								selectedValue ? 
								<Link to={`/launch?iss=${selectedValue.address}&aud=`}>
									<Button
										fullWidth
										className={classes.submit}
										variant="contained"
									>
										Give Consent
									</Button>
								</Link> : 
								<Button
									fullWidth
									className={classes.submit}
									variant="contained"
									disabled
								>
									Give Consent
								</Button>
							}
							
							<Grid container style={{marginTop: 50}}>
								<span>Or you can choose to </span>
								<Link 
									to="/manually-input" 
									style={{ textDecoration: "none", marginLeft: 5, marginRight: 5, color: '#2F80ED'}}
								>
									{"manually input"}
								</Link>
								<span>your information. </span>
							</Grid>
						</form>
					</div>
				</Container>
			</Grid>
		</Grid>
	);
};

export default Consent;